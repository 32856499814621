var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl",
      class: _vm.$route.name == "VrDefault" ? "bg-gradient-default" : "",
    },
    [
      _c(
        "div",
        { staticClass: "px-6 pt-4 pb-0 d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "shadow",
              attrs: {
                color: "bg-gradient-success border-radius-xl mt-n8",
                height: "64",
                width: "64",
              },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-icons-round text-white",
                  attrs: { size: "24" },
                },
                [_vm._v("language")]
              ),
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "font-weight-bold text-h6 mb-0 ms-3",
              class:
                _vm.$route.name == "VrDefault" ? "text-white" : "text-typo",
            },
            [_vm._v(" Sales by Country ")]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "6", md: "7" } },
            [
              _c(
                "v-card-text",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-list",
                    { staticClass: "py-0 bg-transparent" },
                    _vm._l(_vm.countries, function (chat, index) {
                      return _c(
                        "v-list-item",
                        {
                          key: chat.title,
                          staticClass: "px-4 py-1",
                          class: {
                            "border-bottom": index != _vm.countries.length - 1,
                          },
                        },
                        [
                          _c("v-img", {
                            staticClass: "me-6",
                            attrs: {
                              alt: chat.title + " avatar",
                              src: chat.avatar,
                              "max-width": "20px",
                            },
                          }),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-xs font-weight-bold",
                                  class:
                                    _vm.$route.name == "VrDefault"
                                      ? "text-white"
                                      : "text-body",
                                },
                                [_vm._v(" Country: ")]
                              ),
                              _c("v-list-item-title", {
                                staticClass: "text-sm mb-0",
                                class:
                                  _vm.$route.name == "VrDefault"
                                    ? "text-white opacity-6"
                                    : "text-typo",
                                domProps: { textContent: _vm._s(chat.country) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-xs font-weight-bold",
                                  class:
                                    _vm.$route.name == "VrDefault"
                                      ? "text-white"
                                      : "text-body",
                                },
                                [_vm._v(" Sales: ")]
                              ),
                              _c("v-list-item-title", {
                                staticClass: "text-sm mb-0",
                                class:
                                  _vm.$route.name == "VrDefault"
                                    ? "text-white opacity-6"
                                    : "text-typo",
                                domProps: { textContent: _vm._s(chat.sales) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-xs font-weight-bold",
                                  class:
                                    _vm.$route.name == "VrDefault"
                                      ? "text-white"
                                      : "text-body",
                                },
                                [_vm._v(" Value: ")]
                              ),
                              _c("v-list-item-title", {
                                staticClass: "text-sm mb-0",
                                class:
                                  _vm.$route.name == "VrDefault"
                                    ? "text-white opacity-6"
                                    : "text-typo",
                                domProps: { textContent: _vm._s(chat.value) },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-xs font-weight-bold",
                                  class:
                                    _vm.$route.name == "VrDefault"
                                      ? "text-white"
                                      : "text-body",
                                },
                                [_vm._v(" Bounce: ")]
                              ),
                              _c("v-list-item-title", {
                                staticClass: "text-sm mb-0",
                                class:
                                  _vm.$route.name == "VrDefault"
                                    ? "text-white opacity-6"
                                    : "text-typo",
                                domProps: { textContent: _vm._s(chat.bounce) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { lg: "6", md: "5" } }, [
            _c("div", {
              staticClass: "mt-0 mt-lg-n4 w-100 h-100 overflow-hidden",
              attrs: { id: "map" },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }