var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl px-4 py-5",
      class: _vm.$route.name == "VrDefault" ? "bg-gradient-default" : "",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "\n      bg-gradient-success\n      shadow-success\n      border-radius-lg\n      py-3\n      pe-1\n      mb-6\n      mt-n12\n    ",
        },
        [
          _c("div", { staticClass: "chart-area" }, [
            _c("canvas", {
              attrs: {
                height: _vm.$route.name == "VrDefault" ? "370" : "170",
                id: _vm.salesViewsID,
              },
            }),
          ]),
        ]
      ),
      _c(
        "h6",
        {
          staticClass: "ms-2 mt-6 mb-0 text-h6 font-weight-bold",
          class: _vm.$route.name == "VrDefault" ? "text-white" : "text-typo",
        },
        [_vm._v(" Daily Sales ")]
      ),
      _c("p", { staticClass: "text-sm ms-2 text-body font-weight-light" }, [
        _vm._v(" ("),
        _c("span", { staticClass: "font-weight-bold" }, [_vm._v("+15%")]),
        _vm._v(") increase in today sales. "),
      ]),
      _c("hr", { staticClass: "horizontal dark mb-4 mx-2" }),
      _c("div", { staticClass: "d-flex text-body mx-2" }, [
        _c("i", { staticClass: "material-icons-round text-sm my-auto me-1" }, [
          _vm._v("schedule"),
        ]),
        _c("p", { staticClass: "mb-0 text-sm font-weight-light text-body" }, [
          _vm._v("updated 4 min ago"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }